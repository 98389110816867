/*$font-family-default: 'Source Sans Pro', sans-serif;*/
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.color-blue {
  color: #0099D6;
}

.color-accent {
  color: #ff4081;
}

html, body {
  min-height: 100vh;
}

body {
  color: #212529;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  /*@media(max-width:767px)
  {
  	font-size: 16px;
  }*/
}

pcode-root[aria-hidden=true] #app_wrapper,
pcode-root[aria-hidden=true] .content_inner_wrapper {
  filter: blur(3px);
  overflow: hidden;
}

.content_inner_wrapper {
  min-height: 100vh;
}

#ajax-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

/* material*/
mat-error {
  font-size: 12px;
}

.application-container {
  padding: 30px;
  min-height: 100vh;
}
.application-container .application-header {
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 58px;
  padding: 15px 30px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#dee2e6+0,ffffff+100 */
  background: #dee2e6;
  /* Old browsers */
  background: -moz-linear-gradient(top, #dee2e6 0%, #ffffff 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #dee2e6 0%, #ffffff 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #dee2e6 0%, #ffffff 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#dee2e6", endColorstr="#ffffff",GradientType=0 );
  /* IE6-9 */
  z-index: 1000;
}
.application-container .application-header > * {
  align-self: center;
}
.application-container .application-header > *:last-child {
  margin-right: 0 !important;
}
.application-container .application-header .application-header-title {
  display: block;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  flex: 1;
  margin: 0 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.application-container .application-header .application-header-title > small {
  display: block;
  font-size: 16px;
  color: #b2b5b7;
}
.application-container .application-header > img.application-header-logo {
  display: block;
  height: 56px;
  align-self: center;
}
.application-container .application-header .application-header-action {
  display: flex;
  align-self: center;
  margin-right: 20px;
  position: relative;
  color: #212529;
  border-radius: 50%;
  border: 1px solid #dee2e6;
  background: #fff;
  padding: 15px;
  text-decoration: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}
.application-container .application-header .application-header-action > mat-icon {
  font-size: 32px;
  line-height: 32px;
  width: 32px;
  height: 32px;
  text-align: center;
}
.application-container .application-header .application-header-action > img {
  width: 32px;
}
.application-container .application-header .application-header-action.application-header-action--danger {
  background: #e73535;
  border-color: #e73535;
  color: #fff;
}
.application-container .application-header .application-header-action.application-header-action--accent {
  background: #ff4081;
  border-color: #ff4081;
  color: #fff;
}
.application-container .application-header .application-header-action:active, .application-container .application-header .application-header-action:focus {
  background: #ff4081;
  color: #fff;
}
.application-container .application-header .application-header-spacer {
  width: 36px;
}
.application-container .application-body {
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: calc(100vh - 60px);
}
.application-container .application-subtitle {
  display: block;
  max-width: 100%;
  margin: 0 auto 40px auto;
  position: relative;
  padding-bottom: 15px;
  border-bottom: 1px solid #dee2e6;
  text-align: center;
}
.application-container .application-subtitle h3 {
  display: flex;
  justify-content: center;
  margin: 0;
  color: #0099D6;
}
.application-container .application-subtitle h3 > * {
  align-self: center;
}
.application-container .application-subtitle h3 > mat-icon {
  color: #b2b5b7;
}
.application-container .application-subtitle:before {
  display: block;
  position: absolute;
  left: 50%;
  font-family: "Material Icons";
  content: "\e5c5";
  bottom: -24px;
  font-size: 40px;
  line-height: 40px;
  margin-left: -20px;
  width: auto;
  height: auto;
  color: #dee2e6;
}
.application-container .breadcrumbs-list {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 10px auto;
  padding: 0;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
}
.application-container .breadcrumbs-list > li {
  display: flex;
}
.application-container .breadcrumbs-list > li > a {
  display: block;
  align-self: center;
  color: #212529;
  opacity: 0.6;
}
.application-container .breadcrumbs-list > li > mat-icon {
  align-self: center;
  margin: 0 5px;
  font-size: 12px;
  width: 12px;
  height: 12px;
}
.application-container .divider {
  position: relative;
  height: 1px;
  margin: 50px 0;
}
.application-container .divider:before {
  content: "";
  position: absolute;
  top: 0;
  left: 5%;
  right: 5%;
  width: 90%;
  height: 1px;
  background-image: linear-gradient(to right, transparent, #b2b5b7, transparent);
}
.application-container .divider:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -9px;
  left: calc(50% - 9px);
  width: 18px;
  height: 18px;
  background-color: #5fe2ad;
  border: 1px solid #5fe2ad;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px white, 0 0 0 4px white;
}
.application-container .mat-expansion-panel-header {
  padding: 0 15px;
}
.application-container .mat-expansion-panel-body {
  padding: 0 15px 15px;
}
.application-container mat-icon {
  width: auto;
  height: auto;
}
@media (max-width: 767px) {
  .application-container {
    padding: 10px;
  }
  .application-container .application-header {
    padding: 10px;
  }
  .application-container .application-header .application-header-title {
    margin: 0 10px 0 0;
    font-size: 18px;
    line-height: inherit;
  }
  .application-container .application-header .application-header-title > small {
    margin-top: 5px;
    font-size: 12px;
    line-height: 15px;
    color: #999;
    font-weight: normal;
  }
  .application-container .application-header > img.application-header-logo {
    display: none;
    height: 24px;
  }
  .application-container .application-header .application-header-action {
    padding: 5px;
    margin-right: 10px;
  }
  .application-container .application-header .application-header-action > mat-icon {
    font-size: 16px;
    line-height: 24px;
    width: 24px;
    height: 24px;
  }
  .application-container .application-body {
    padding-top: 60px;
    padding-bottom: 60px;
    min-height: calc(100vh - 20px);
  }
  .application-container .application-subtitle {
    max-width: 100%;
    margin: 0 auto 24px auto;
  }
  .application-container .application-subtitle h3 {
    font-size: 20px;
  }
  .application-container .breadcrumbs-list {
    display: none;
  }
}

.pwa-paginator {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.pwa-paginator ::ng-deep mat-paginator {
  background: #f8f9fa;
  padding: 0 10px;
  border-radius: 100px;
  font-size: 14px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05);
}
.pwa-paginator ::ng-deep mat-paginator .mat-paginator-container {
  padding: 0;
}
.pwa-paginator ::ng-deep mat-paginator .mat-paginator-container .mat-paginator-page-size {
  display: none;
}
.pwa-paginator ::ng-deep mat-paginator .mat-paginator-container .mat-paginator-range-actions {
  background: #fff;
  border-radius: 100px;
}
.pwa-paginator ::ng-deep mat-paginator .mat-paginator-container .mat-paginator-range-actions button {
  margin-right: 10px;
}
.pwa-paginator ::ng-deep mat-paginator .mat-paginator-container .mat-paginator-range-actions button:last-child {
  margin-right: 0;
}
.pwa-paginator ::ng-deep mat-paginator .mat-paginator-container .mat-paginator-range-actions button:not(.mat-button-disabled) {
  background: #ff4081;
  color: #fff;
}

.empty-page {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.empty-page img {
  display: block;
  max-width: 200px;
  margin: 0 auto 30px auto;
}
.empty-page h1 {
  color: #b2b5b7;
  text-align: center;
  font-size: 24px;
}
.empty-page h2 {
  text-align: center;
  font-size: 20px;
}
.empty-page button {
  margin-top: 30px;
}
@media (max-width: 767px) {
  .empty-page img {
    max-width: 100px;
  }
}

.pwa-dialog-overlay-wrapper {
  background: rgba(0, 153, 214, 0.8);
  box-shadow: inset 0 0 10px 5px rgba(0, 0, 0, 0.5);
}

.pwa-dialog-panel mat-dialog-container {
  border: 3px solid #5fe2ad;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.pwa-dialog-panel mat-dialog-container form.webapp-form {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.pwa-dialog-panel .mat-dialog-content {
  flex: 1;
  max-height: initial;
  padding-bottom: 30px;
}
.pwa-dialog-panel .mat-dialog-title {
  display: flex;
  color: #676767;
  font-size: 16px;
  justify-content: center;
}
.pwa-dialog-panel .mat-dialog-title > i, .pwa-dialog-panel .mat-dialog-title span, .pwa-dialog-panel .mat-dialog-title mat-icon {
  align-self: center;
}
.pwa-dialog-panel .mat-dialog-title > i, .pwa-dialog-panel .mat-dialog-title mat-icon {
  margin-right: 10px;
}
.pwa-dialog-panel .mat-dialog-actions {
  justify-content: center;
  margin: 0;
  padding: 0;
  background: #fff;
}
.pwa-dialog-panel .mat-dialog-actions button {
  flex: 1;
}

.light-dialog-overlay-wrapper {
  background: rgba(255, 255, 255, 0.7);
}

/* layer menu dialog*/
.pwa-layer-menu-dialog-panel mat-dialog-container {
  padding: 0;
  background: none;
  display: flex;
  justify-content: center;
}

/* form */
.webapp-form .form-group {
  margin: 10px 0;
  padding: 10px 0;
}
.webapp-form .form-separator {
  border-top: 1px dashed #bbb;
  margin: 20px 0;
}
.webapp-form .form-title {
  display: flex;
  color: #676767;
  border-bottom: 1px dashed #dee2e6;
  padding-bottom: 10px;
}
.webapp-form .form-title mat-icon {
  font-size: 18px;
  height: 15px;
  width: 15px;
}
.webapp-form .form-title > * {
  align-self: center;
}
.webapp-form .mat-form-field-appearance-outline .mat-form-field-wrapper {
  padding-bottom: 0;
  margin-bottom: 0;
}
.webapp-form .mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-subscript-wrapper {
  margin-top: 26px;
}
.webapp-form .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  font-size: 18px;
}
.webapp-form .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label .mat-form-field-required-marker {
  color: #e73535;
}
.webapp-form button.disabled, .webapp-form button:disabled {
  background-color: #bbb !important;
}
.webapp-form.webapp-form-readonly .form-group {
  margin: 5px 0;
  padding: 5px 0;
}
.webapp-form .mat-form-field-appearance-outline.mat-form-field-disabled,
.webapp-form .mat-form-field-appearance-outline.mat-select-disabled,
.webapp-form .readonly-field {
  border: 1px solid #ddd;
  border-bottom-width: 3px;
  border-radius: 5px;
  padding: 15px 15px 10px 15px;
}
.webapp-form .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-wrapper,
.webapp-form .mat-form-field-appearance-outline.mat-select-disabled .mat-form-field-wrapper,
.webapp-form .readonly-field .mat-form-field-wrapper {
  padding: 0;
  margin: 0 0;
}
.webapp-form .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-wrapper .mat-form-field-infix,
.webapp-form .mat-form-field-appearance-outline.mat-select-disabled .mat-form-field-wrapper .mat-form-field-infix,
.webapp-form .readonly-field .mat-form-field-wrapper .mat-form-field-infix {
  padding-top: 8px;
  padding-bottom: 0;
}
.webapp-form .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-wrapper input,
.webapp-form .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-wrapper textarea,
.webapp-form .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-wrapper .mat-select-min-line,
.webapp-form .mat-form-field-appearance-outline.mat-select-disabled .mat-form-field-wrapper input,
.webapp-form .mat-form-field-appearance-outline.mat-select-disabled .mat-form-field-wrapper textarea,
.webapp-form .mat-form-field-appearance-outline.mat-select-disabled .mat-form-field-wrapper .mat-select-min-line,
.webapp-form .readonly-field .mat-form-field-wrapper input,
.webapp-form .readonly-field .mat-form-field-wrapper textarea,
.webapp-form .readonly-field .mat-form-field-wrapper .mat-select-min-line {
  color: #212529;
}
.webapp-form .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-wrapper .mat-select-arrow,
.webapp-form .mat-form-field-appearance-outline.mat-select-disabled .mat-form-field-wrapper .mat-select-arrow,
.webapp-form .readonly-field .mat-form-field-wrapper .mat-select-arrow {
  color: transparent !important;
}
.webapp-form .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-wrapper .mat-form-field-flex,
.webapp-form .mat-form-field-appearance-outline.mat-select-disabled .mat-form-field-wrapper .mat-form-field-flex,
.webapp-form .readonly-field .mat-form-field-wrapper .mat-form-field-flex {
  padding: 0;
}
.webapp-form .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-wrapper .mat-form-field-outline,
.webapp-form .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-wrapper .mat-form-field-suffix,
.webapp-form .mat-form-field-appearance-outline.mat-select-disabled .mat-form-field-wrapper .mat-form-field-outline,
.webapp-form .mat-form-field-appearance-outline.mat-select-disabled .mat-form-field-wrapper .mat-form-field-suffix,
.webapp-form .readonly-field .mat-form-field-wrapper .mat-form-field-outline,
.webapp-form .readonly-field .mat-form-field-wrapper .mat-form-field-suffix {
  display: none;
}

.mat-form-field {
  width: 100%;
}
.mat-form-field .mat-form-field-infix {
  width: auto;
}

.filters-box {
  padding-bottom: 30px;
  /*.mat-form-field-appearance-outline .mat-form-field-infix
  {
  	padding: 10px 0;
  }*/
}
.filters-box .webapp-form .form-group {
  margin: 0 0 15px 0;
  padding: 0;
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.pagination {
  display: flex;
  justify-content: space-between;
}
.pagination button {
  display: flex;
}
.pagination button:first-child mat-icon {
  margin-right: 10px;
}
.pagination button:last-child mat-icon {
  margin-left: 10px;
}
.pagination button.disabled {
  color: #ccc;
}

.mat-snack-bar-handset snack-bar-container.mat-snack-bar-container {
  margin: 10px 10px 20px 10px;
  border-radius: 0;
}
.mat-snack-bar-handset snack-bar-container.mat-snack-bar-container .mat-simple-snackbar {
  justify-content: center;
}
.mat-snack-bar-handset snack-bar-container.mat-snack-bar-container .mat-simple-snackbar-action {
  display: none;
  /*button
  {
  	@include border-radius-100;
  	@include box-shadow-inset-small;
  	background: #fff;
  	color: $color-accent;
  	outline: none;
  }*/
}
.mat-snack-bar-handset snack-bar-container.mat-snack-bar-container.snackbar--success {
  background: #5fe2ad;
  color: #116744;
}
.mat-snack-bar-handset snack-bar-container.mat-snack-bar-container.snackbar--error {
  background: #e73535;
  color: #fff;
}

/* data list */
.data_list {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0 0 0 0;
}
.data_list > li {
  display: block;
  margin-bottom: 15px;
}
.data_list > li > a {
  display: block;
  background: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-bottom-width: 3px;
  border-radius: 5px;
}
.data_list > li > a > .data_list_header {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  text-transform: uppercase;
}
.data_list > li > a > .data_list_header > div {
  flex: 1;
}
.data_list > li > a .data_list_title {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
.data_list > li:nth-child(even) > a {
  background: #f6f6f6;
}

.info_list {
  display: block;
  list-style: none;
}
.info_list > li {
  display: block;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #dee2e6;
  border-bottom-width: 3px;
  border-radius: 10px;
}
.info_list > li > strong {
  display: block;
  color: #2c60b3;
  text-transform: uppercase;
  font-size: 11px;
}
.info_list > li > span {
  display: block;
  line-height: normal;
}
.info_list > li .work_team_list {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}
.info_list > li .work_team_list > li {
  display: block;
  margin: 0 0 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-bottom-width: 3px;
  border-radius: 5px;
}
.info_list > li .work_team_list > li > ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}
.info_list > li .work_team_list > li > ul > li {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.info_list > li .work_team_list > li > ul > li > mat-icon {
  width: auto;
  height: auto;
  font-size: inherit;
  margin-right: 10px;
  text-align: center;
}
.info_list > li .work_team_list > li > ul > li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.info_list > li .work_team_list > li:last-child {
  margin-bottom: 0;
}
.info_list > li:last-child {
  margin-bottom: 0;
}

.details_list {
  display: block;
  list-style: none;
}
.details_list > li {
  display: flex;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px dashed #ddd;
}
.details_list > li.block {
  display: block;
}
.details_list > li > strong {
  align-self: flex-start;
  margin-right: 5px;
}
.details_list > li > span {
  align-self: flex-start;
}
.details_list > li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.quickactions {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.quickactions > div {
  flex: 33%;
  display: flex;
}
.quickactions > div > button {
  display: block;
  width: 100%;
  text-align: center;
  margin: 5px;
  padding: 15px 15px 20px;
  box-shadow: inset 0 -5px 0 0 rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}
.quickactions > div > button > mat-icon {
  display: block;
  font-size: 48px;
  margin: 0 0 10px 0;
  text-align: center;
}
.quickactions > div > button > span {
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: bold;
}
.quickactions > div > button:disabled {
  opacity: 0.5;
  background: #ddd;
  box-shadow: none;
}
.quickactions > div > button:disabled > mat-icon, .quickactions > div > button:disabled > span {
  color: #676767;
}

.layer_menu_container {
  position: fixed;
  bottom: 60px;
  right: 10px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 100;
}
.layer_menu_container button {
  margin-bottom: 10px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.layer_menu_container button > mat-icon {
  align-self: center;
}
.layer_menu_container button.layer_menu_container_trigger {
  z-index: 10;
}
.layer_menu_container button:not(.layer_menu_container_trigger) {
  opacity: 0;
  position: absolute;
  bottom: 0;
  z-index: 9;
}
.layer_menu_container.layer_menu_container--open .layer_menu_container_trigger {
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.layer_menu_container.layer_menu_container--open > button:not(.layer_menu_container_trigger) {
  opacity: 1;
}
.layer_menu_container.layer_menu_container--open > button:not(.layer_menu_container_trigger):nth-child(2) {
  bottom: 70px;
}
.layer_menu_container.layer_menu_container--open > button:not(.layer_menu_container_trigger):nth-child(3) {
  bottom: 140px;
}

/* ### */
.hidden-i {
  display: none !important;
}